import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'

const FaqsAccordion = ({ QandAs, subTitle }) => {
  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <h2 className="text-center">Frequently Asked Questions</h2>
        {subTitle && <p className="text-center">{subTitle}</p>}
      </div>
      {QandAs?.map((item, index) => (
        <Accordion
          disableGutters
          style={{
            boxShadow: 'none',
            borderBottom: '1px solid rgb(245 245 245)',
          }}
          key={index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            style={{ padding: '0', margin: '0' }}
          >
            <Header key={item.index}>{item.question ? item.question : null}</Header>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0', margin: '0' }}>
            <Content
              dangerouslySetInnerHTML={{
                __html: item.answer?.childMarkdownRemark.html,
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

const Header = styled.h3`
	font-size: 21px;
  color: rgb(38 75 101);
  padding: 0;
  margin: 10px 0;
  @media screen and (max-width: 480px) {
		font-size: 18px;
	};
`

const Content = styled.div`
  color: rgb(38 75 101);
  font-size: 16px;
  width: 1020px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
  p {
    font-size: 18px !important;
    text-align: left !important;
    @media screen and (max-width: 991px) {
      width: 95vw !important;
    }
  }
`

export default FaqsAccordion
