import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import TopicDescAndImg from '../UI/TopicDescAndImg'

const PrivacyandSecurity = () => {
  return (
    <ContentContainer background="var(--base-gray)" maxWidth="1020px">
      <Container>
        <h2>Pay safer and faster with Apple Pay.</h2>
        <p className="large-text">
          Using Apple Pay is simple, and it works with the devices you use every
          day. Your card information is secure because it isn’t stored on your
          device or shared when you pay. Paying in stores, in selected apps and
          on participating websites has never been easier, safer or more
          private.
        </p>
      </Container>
      <TopicDescAndImg categoryQuery="Security and Privacy" />
    </ContentContainer>
  )
}

const Container = styled.div`
  background-color: var(--base-navy);
  color: var(--white);
  margin-bottom: 80px;
  padding: 40px;
  border-radius: 16px;
`

export default PrivacyandSecurity
