import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import ApplePayHero from '../components/ForApplePay/ApplePayHero'
import PrivacyandSecurity from '../components/ForApplePay/PrivacyandSecurity'
import HowToSpendOne from '../components/ForApplePay/HowToSpendOne'
import HowToApplePay from '../components/ForApplePay/HowToApplePay'
import WhereToUseApplePay from '../components/ForApplePay/WhereToUseApplePay'
import AppQRCode from '../components/UI/AppQRCode'
import ApplePayFAQ from '../components/ForApplePay/ApplePayFAQ'

const ApplePay = ({ path }) => {
  const applePayFAQ = useStaticQuery(graphql`
  query {
    allContentfulComponentFaQs(filter: {category: {eq: "Apple Pay"}}) {
      nodes {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`)
const faqs = applePayFAQ.allContentfulComponentFaQs.nodes
const faqsStructuredData = faqs.map(faq => {
    return {
      "name": faq.question,
      "@type": "Question",
      "acceptedAnswer": {
        "text": faq.answer.childMarkdownRemark.html,
        "@type": "Answer"
      }
    }
  })

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "SpendOne with Apple Pay",
    "datePublished": "2022-06-22",
    "faq": {
      "@type": "FAQPage",
      "mainEntity": faqsStructuredData
    }
  }
  return (
    <Layout showTandC={false}>
      <Seo title="Pay Safer & Faster With Apple Pay" path={path} description="Pay safer and faster with Apple Pay. Using Apple Pay is simple, and it works with the devices you use every day. Sign up with SocietyOne Today." structuredData={structuredData}/>
      <Container>
        <ApplePayHero />
        <PrivacyandSecurity />
        <HowToSpendOne />
        <HowToApplePay />
        <WhereToUseApplePay />
        <hr className="solid" />
        <ApplePayFAQ faqs={faqs} />
        <AppQRCode apple />
      </Container>
    </Layout>      
  )
}

const Container = styled.div`
  .large-text {
    font-size: 20px;
  }
  .extra-large {
    font-size: 24px;
  }
  .svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    .extra-large {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
`

export default ApplePay
