import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// For more information: https://www.gatsbyjs.com/plugins/gatsby-transformer-inline-svg/

/**
 * Usual CMS image data structure is:
 *  title
 *  gatsbyImageData
 *  svg {
 *    content
 *  }
 *  file {
 *    contentType
 *    url
 *    ! Give width and height to Img to optimise Cumulative Layout Shift (https://society1.atlassian.net/browse/SB-2641)
 *    details {
 *      image {
 *        width
 *        height
 *      }
 *    }
 *  }
 */

function CMSImage({ svg, gatsbyImageData, file, internal, alt }) {
  let element
  if (file?.contentType === 'image/svg+xml' || internal?.mediaType === 'image/svg+xml') {
    if (svg && svg.content) {
      // Inlined SVGs
      // Be aware that sometimes some <path /> elements in svg file won't be rendered correctly by rendering like this.
      element = <div dangerouslySetInnerHTML={{ __html: svg.content }} />
    } else {
      // SVGs that can/should not be inlined
      element = <img width={file.details?.image.width} height={file.details?.image.height} src={file.url} alt={alt} />
    }
  } else {
    // Non SVG images
    element = <GatsbyImage image={gatsbyImageData} alt={alt} />
  }

  return element
}

export default CMSImage
