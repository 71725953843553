import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import FaqsAccordion from '../UI/FaqsAccordion'

const ApplePayFAQ = ({faqs}) => {
  const queriedApplePayFAQ = useStaticQuery(graphql`
    query ApplePayFAQ {
      contentfulGenericHeadingTextAndIcon (
        contentIdentifier: { eq: "ForApplePay/ApplePayFAQ" }
      ) {
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <ContentContainer>
      <Container>
        <FaqsAccordion
          QandAs={faqs}
        />
        <div 
          style={{ marginTop: 120 }}
          dangerouslySetInnerHTML={{
            __html: queriedApplePayFAQ.contentfulGenericHeadingTextAndIcon.content.childMarkdownRemark.html,
          }}
        />

      </Container>
    </ContentContainer>
  )
}

const Container = styled.div`
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
      :hover {
        color: inherit;
      }
    }
  }
`

export default ApplePayFAQ
