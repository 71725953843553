import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import Video from '../UI/Video'
import Number1Icon from '../../assets/images/svg/number-1-icon.svg'
import Number2Icon from '../../assets/images/svg/number-2-icon.svg'

const HowToApplePay = () => {
  return (
    <ContentContainer
      background="var(--base-gray)"
      maxWidth="1020px"
    >
      <Container>
        <h2>How to use Apple Pay</h2>
        <p className="extra-large">
          Apple Pay is the safer, faster way to pay in store and online.There
          are two ways to use your SpendOne card with Apple Pay.
        </p>
        <div className="subPara">
          <h3>Paying in stores</h3>
          <p>Use Apple Pay on your iPhone to pay safer and faster in stores.</p>
        </div>
        <section>
          <div>
            <div className="row">
              <div className="imgWrapper">
                <Number1Icon />
              </div>
              <p>
                <strong className="quicksand">Pay with Face ID</strong>:
                double-click the side button, hold the screen up to recognise
                your face, then hold your iPhone near the reader.
              </p>
            </div>
          </div>
          <Video
            videoSrcURL="https://www.youtube.com/embed/9sUF8hZ3kVo"
            videoTitle="Apple Pay — How to pay with Face ID on iPhone X— Apple"
          />
        </section>
        <section>
          <div>
            <div className="row">
              <div className="imgWrapper">
                <Number2Icon />
              </div>
              <p>
                <strong className="quicksand">Pay with Touch ID</strong>: hold
                your iPhone near the reader with your finger on Touch ID.
              </p>
            </div>
          </div>
          <Video
            videoSrcURL="https://www.youtube.com/embed/35mdHemHWZk"
            videoTitle="Apple Pay — How to pay with Touch ID on iPhone — Apple"
          />
        </section>
        <div className="subPara">
          <h3>Paying in apps and on websites</h3>
          <p>
            Use Apple Pay to avoid creating an account or filling out lengthy
            forms when paying in apps and online.
          </p>
        </div>
        <div className="flex-row col">
          <div className="flex-row">
            <div className="imgWrapper">
              <Number1Icon />
            </div>
            <p>
              To pay in selected apps and on participating websites in Safari on
              your iPhone and iPad.
            </p>
          </div>
          <div className="flex-row">
            <div className="imgWrapper">
              <Number2Icon />
            </div>
            <p>
              Select Apple Pay at the check-out and complete the payment using
              Face ID or Touch ID.
            </p>
          </div>
        </div>
      </Container>
    </ContentContainer>
  )
}

const Container = styled.div`
  text-align: center;
  .subPara {
    text-align: left;
    margin: 80px 0 32px;
  }
  section {
    text-align: left;
    margin-top: 80px;
    display: grid;
    grid-gap: 80px;
    p {
      margin-bottom: 40px;
    }
  }
  .video {
    padding: 56% 0 0 0;
    position: relative;
    width: 100%;
    height: 100px;
    background-size: cover;
    background-position: 50% 50%;
    iframe {
      position: absolute;
      pointer-events: auto;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .flex-row {
    margin-bottom: 30px;
    text-align: left;
    align-items: start;
    p {
      margin-top: 0;
    }
  }
  .imgWrapper {
    margin: 0 10px;
    flex-shrink: 0;
    image {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    section {
      grid-column-gap: 40px;
      grid-row-gap: 32px;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      p {
        margin-top: 0;
      }
    }
    .col {
      flex-direction: column;
    }
  }
  @media screen and (min-width: 990px) {
    section {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      align-items: center;
    }
  }
`

export default HowToApplePay
