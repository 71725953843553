import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import Video from '../UI/Video'
import Number1Icon from '../../assets/images/svg/number-1-icon.svg'
import Number2Icon from '../../assets/images/svg/number-2-icon.svg'

const HowToSpendOne = () => {
  return (
    <ContentContainer maxWidth="1020px">
      <Container>
        <h2>How to add your SpendOne card to Apple Pay</h2>
        <p className="extra-large">Adding your card to Apple Pay is simple:</p>
        <section>
          <div>
            <div className="row">
              <div className="imgWrapper">
                <Number1Icon />
              </div>
              <p>
                Open your Wallet app and tap the ‘+’ sign in the upper-right
                corner
              </p>
            </div>
            <div className="row">
              <div className="imgWrapper">
                <Number2Icon />
              </div>
              <p>
                Tap ‘Add different card’ and use your iPhone camera to scan the
                card information
              </p>
            </div>
          </div>
          <Video
            videoSrcURL="https://www.youtube.com/embed/ovVXiDsfIM8"
            videoTitle="Apple Pay — How to add a card on iPhone — Apple"
          />
        </section>
      </Container>
    </ContentContainer>
  )
}

const Container = styled.div`
  text-align: center;
  section {
    text-align: left;
    margin-top: 80px;
    display: grid;
    grid-gap: 80px;
    p {
      margin-bottom: 40px;
    }
    .video {
      padding: 56% 0 0 0;
      position: relative;
      width: 100%;
      height: 100px;
      background-size: cover;
      background-position: 50% 50%;
      iframe {
        position: absolute;
        pointer-events: auto;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    section {
      grid-column-gap: 40px;
      grid-row-gap: 32px;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      p {
        margin-top: 0;
      }
      .imgWrapper {
        margin: 0 10px;
        flex-shrink: 0;
        image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 990px) {
    section {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      align-items: center;
    }
  }
`

export default HowToSpendOne
