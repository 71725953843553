import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import ApplePaySign from '../../assets/images/svg/where-to-apple-pay.svg'

const WhereToUseApplePay = () => {
  return (
    <ContentContainer
      maxWidth="1020px"
    >
      <WhereToUseApplePayWrapper>
        <h2>Where to use Apple Pay</h2>
        <p className="extra-large">Use Apple Pay wherever you see one of these symbols.</p>
        <div className="logo-wrapper">
          <ApplePaySign className="svg" />
        </div>
      </WhereToUseApplePayWrapper>
    </ContentContainer>
  )
}

const WhereToUseApplePayWrapper = styled.div`
  flex-direction: column;
  text-align: center;
  h1 {
    font-size: 56px;
    line-height: 1.3;
    letter-spacing: 0;
    max-width: 500px;
    margin-bottom: 44px;
  }
  .logo-wrapper {
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    height: auto;
  }
`

export default WhereToUseApplePay
