import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
import S1ApplePayIcon from '../../assets/images/svg/society-one-apple-pay-logo.svg'

const ApplePayHero = () => {

  return (
    <ContentContainer
      maxWidth="1020px"
    >
      <ApplePayHeroWrapper>
        <div className="col">
          <h1>SpendOne with Apple Pay</h1>
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_L}
            padding={BUTTON_SIZE.PADDING_L}
            fontSize={BUTTON_SIZE.FONT_SIZE_L}
            background={BUTTON_COLOR.GREEN}
            backgroundHover={BUTTON_COLOR.GREEN_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            text="Download The SocietyOne App"
            margin="0"
            handleClick={
              typeof document !== 'undefined'
                ? () => document.getElementById('app-download').scrollIntoView()
                : null
            }
          />
          <div className="logo">
            <S1ApplePayIcon className="svg" />
          </div>
        </div>
        <div className="col heroImg">
          <StaticImage
            src="../../assets/images/apple-pay-hero.png"
            alt="apple-pay-hero-image"
            placeholder='none'
          />
        </div>
      </ApplePayHeroWrapper>
    </ContentContainer>
  )
}

const ApplePayHeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    max-width: 500px;
    margin-bottom: 44px;
  }
  .logo {
    max-width: 300px;
    margin-top: 64px;
  }
  .heroImg {
    width: 100%;
    max-width: 400px;
  }
  .col {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    button {
      margin: 0 auto;
    }
    .logo {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default ApplePayHero
